import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataMappingAuthResponse, GoogleDataMappingGroupResponse, GoogleDirectoryGroupsRequest, MappingProgressResponse } from './models/data-mapping/data-mapping.interface';
import { FeatureFlagQuery } from '../feature-flag/state/feature-flag.query';
import { LoginCallbackResponse } from '../auth/login-callback-response';
import { HttpClientHelperService } from './http-client-helper.service';
import { AuthProviders } from './models/auth/auth-providers';
import { CallbackArgs } from './models/auth/callback-args';
import { FeatureFlags } from './models/profile/profile-feature-flags.enum';

@Injectable({
	providedIn: 'root',
})
export class ApiClientDataMappingService {

	private readonly controller = 'DataMapping';

	private readonly googleAuthController = 'GoogleDataMappingAuth';

	private readonly microsoftController = 'MicrosoftDataMappingAuth';

	private readonly dataMappingGroupsController = 'DataMappingGroups';

	constructor(
		private featureFlagQuery: FeatureFlagQuery,
		private httpClientHelper: HttpClientHelperService,
	) { }

	getDataMappingProgress(): Observable<MappingProgressResponse> {
		return this.httpClientHelper.invokeGetAuth<MappingProgressResponse>(`${this.controller}/Progress`);
	}

	getAuthDataMapping(providerUrl: string): Observable<DataMappingAuthResponse> {
		return this.httpClientHelper.invokeGetAuth<DataMappingAuthResponse>(providerUrl);
	}

	handleAuthCallback(args: CallbackArgs, provider: AuthProviders): Observable<LoginCallbackResponse> {
		return this.httpClientHelper.invokePost(`${provider}datamappingAuth/callback`, args);
	}

	getAdminInstallResult(code: string, redirectUrl: string): Observable<void> {
		return this.httpClientHelper.invokeGetAuth<void>(`${this.microsoftController}/MultiTenantCallback?code=${code}&redirect=${redirectUrl}`);
	}

	getAdminInstallRedirectUrl(redirectUrl: string): Observable<DataMappingAuthResponse> {
		return this.httpClientHelper.invokeGetAuth<DataMappingAuthResponse>(`${this.microsoftController}/AdminConsentUrl?redirect=${redirectUrl}`);
	}

	connectWorkspace(email: string): Observable<void> {
		const devGroupsDiscoveryFlag = this.featureFlagQuery.getFlag(FeatureFlags.DevGroupsDiscovery);
		return this.httpClientHelper.invokeGetAuth<void>(`${this.googleAuthController}/${devGroupsDiscoveryFlag ? 'v2/MultiTenantCallback' : 'MultiTenantCallback'}?emailAddress=${email}`);
	}

	getAvailableGroups(query: string): Observable<GoogleDataMappingGroupResponse> {
		return this.httpClientHelper.invokeGetAuth<GoogleDataMappingGroupResponse>(`${this.dataMappingGroupsController}/AvailableGroups?query=${encodeURIComponent(query)}`);
	}

	getGroupsWhitelist(): Observable<GoogleDataMappingGroupResponse> {
		return this.httpClientHelper.invokeGetAuth<GoogleDataMappingGroupResponse>(`${this.dataMappingGroupsController}/v1/GoogleWhitelist`);
	}

	setGroupsWhitelist(request: GoogleDirectoryGroupsRequest): Observable<GoogleDataMappingGroupResponse> {
		return this.httpClientHelper.invokePost<GoogleDataMappingGroupResponse>(`${this.dataMappingGroupsController}/v1/SetGoogleWhitelistAndTriggerDiscovery`, request);
	}

	updateGroupsWhitelist(request: GoogleDirectoryGroupsRequest): Observable<GoogleDataMappingGroupResponse> {
		return this.httpClientHelper.invokePutAuth<GoogleDataMappingGroupResponse>(`${this.dataMappingGroupsController}/v1/GoogleWhitelist`, request);
	}
}