import { Injectable } from '@angular/core';
import { iif, Observable, of, tap, map } from 'rxjs';
import { ApiClientAutomationsService } from '../api/api-client-automations.service';
import { AdditionalTemplateRequest } from '../api/models/automations/automation-update.interface';
import { Automation, AutomationLocalized } from '../api/models/automations/automations.interface';
import { LanguageConfiguration } from '../privacy-center/localization.interface';
import { ProfileQuery } from '../profile/state/profile.query';
import { ProfileService } from '../profile/state/profile.service';
import { ContentPipe } from '../services/content/content.pipe';
import { AutomationsQuery } from './state/automations.query';
import { AutomationsService } from './state/automations.service';
import { AutomationStore } from './state/automations.store';
import { FeatureFlagQuery } from '../feature-flag/state/feature-flag.query';
import { FeatureFlags } from '../api/models/profile/profile-feature-flags.enum';

@Injectable({
	providedIn: 'root'
})
export class AutomationsLocalizationService  {
	private defaultLocale: LanguageConfiguration;
	// Previously added languages by user; comes from Server Info call
	private configuredLocales: string[];
	// Map of all languages that Mine listed in Squidex, will be always full;
	private availableLocales: Map<string, LanguageConfiguration> = new Map();

	constructor(
		private profileQuery: ProfileQuery,
		private contentPipe: ContentPipe,
		private profileService: ProfileService,
		private apiClientAutomationsService: ApiClientAutomationsService,
		private automationStore: AutomationStore,
		private automationQuery: AutomationsQuery,
		private automationsService: AutomationsService,
		private featureFlagsQuery: FeatureFlagQuery
	) {  }

	initService(): void {
		this.defaultLocale = this.contentPipe.transform('templates-localization.defaultLanguage');
		this.initConfiguredLocales();
		this.initLocalesMap();
		if(!this.automationQuery.hasActive()) {
			this.automationStore.setActive(this.defaultLocale.localizationCode);
		}
	}

	getDefaultLocaleConfiguration(): LanguageConfiguration {
		return this.defaultLocale;
	}

	getDefaultLocaleCode(): string {
		return this.defaultLocale.localizationCode;
	}

	getConfiguredLocales(): string[] {
		return this.configuredLocales;
	}

	getLocaleConfigByCode(localeCode: string): LanguageConfiguration {
		return this.availableLocales.get(localeCode);
	}

	getAvailableLocales(): Map<string, LanguageConfiguration> {
		return new Map(this.availableLocales);
	}

	addTemplatesLanguage(locale: string): Observable<string> {
		const req: AdditionalTemplateRequest = { additionalTemplateLocale: locale };
		return this.apiClientAutomationsService.addAdditionalLanguage(req).pipe(
			tap(() => this.addNewConfiguredLocale(locale)),
			map(() => this.profileService.updateProfileValue<string[]>(this.configuredLocales, 'additionalTemplateLocales')),
			map(() => this.addNewAutomationsToStore(locale)),
			map(() => locale)
		);
	}

	handleActiveLocaleChange(locale: string): Observable<void> {
		const localizedAutomationExists: boolean = this.automationQuery.hasEntity(locale);
		this.addNewAutomationsToStore(locale);
		return iif(() => !localizedAutomationExists, this.automationsService.getAutomations(locale), of(locale)).pipe(
			map(() => this.automationStore.setActive(locale))
		);
	}

	private addNewAutomationsToStore(locale: string): void {
		const localeConfig: LanguageConfiguration = this.availableLocales.get(locale);
		const defaultTemplates: AutomationLocalized = this.automationQuery.getEntity(this.defaultLocale.localizationCode);
		const newTemplates: Automation[] = [];
		defaultTemplates.automations.forEach((item) => {
			newTemplates.push(this.initLocalizedAutomation(item, localeConfig));
		});
		this.automationStore.add({ locale: locale, automations: newTemplates } as AutomationLocalized);
	}

	private initLocalizedAutomation(item: Automation, localeConfig: LanguageConfiguration): Automation {
		return {...item, 
			content: {
				...item.content, 
				createdAt: '',
				lastModified: '',
				lastUsed: '',
				template: '',
				templateLocale: localeConfig.localizationCode,
				alignedLeft: !localeConfig.rtl
			}
		} as Automation
	}

	removeTemplatesLanguage(locale: string): Observable<string> {
		return this.apiClientAutomationsService.removeAdditionalLocale(locale).pipe(
			tap(() => this.removeConfiguredLocale(locale)),
			tap(() => this.setLocaleConfigured(this.availableLocales.get(locale), false)),
			map(() => this.profileService.updateProfileValue<string[]>(this.configuredLocales, 'additionalTemplateLocales')),
			map(() => locale)
		);
	}

	private removeConfiguredLocale(locale: string) {
		const index = this.configuredLocales.findIndex(item => item === locale);
		this.configuredLocales.splice(index,1);
	}

	private addNewConfiguredLocale(locale: string) {
		this.configuredLocales = [...this.configuredLocales, locale];
		this.setLocaleConfigured(this.availableLocales.get(locale), true);
	}

	private setLocaleConfigured(config: LanguageConfiguration, value: boolean = false): void {
		this.availableLocales.set(config.localizationCode, { ...config, configured: value });
	}

	private initConfiguredLocales(): void {
		this.configuredLocales = this.profileQuery.getValue().additionalTemplateLocales ?? [];
		if(this.configuredLocales.includes(this.defaultLocale.localizationCode)){
			return;
		}
		this.configuredLocales = [this.defaultLocale.localizationCode, ...this.configuredLocales];
	}

	private initLocalesMap(): void {
		if (!!this.availableLocales && this.availableLocales.size) {
			return;
		}

		this.availableLocales.set(this.defaultLocale.localizationCode, {...this.defaultLocale , configured: true} as LanguageConfiguration);
		
		const localesFromCMS: LanguageConfiguration[] = this.contentPipe.transform('templates-localization.languages');
		const configuredSet = new Set(this.configuredLocales);

		localesFromCMS.forEach(localeConfig => { 
			const configured  =  configuredSet.has(localeConfig.localizationCode);
			this.setLocaleConfigured(localeConfig, configured);
		});

		this.availableLocales = new Map([...this.availableLocales.entries()].sort((a,b) => this.sortByAlphabet(a[1], b[1])));

		if (this.featureFlagsQuery.getFlag(FeatureFlags.DevCustomLanguages)) {
			const customLanguages = this.contentPipe.transform('templates-localization.custom-languages') as LanguageConfiguration[];
			customLanguages.forEach(localeConfig => { 
				const configured  =  configuredSet.has(localeConfig.localizationCode);
				this.setLocaleConfigured(localeConfig, configured);
			});
		}
	}

	private sortByAlphabet(a: LanguageConfiguration, b: LanguageConfiguration): number {
		return a.nameForDisplay.localeCompare(b.nameForDisplay);
	}
}
