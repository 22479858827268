import { Component, ChangeDetectionStrategy, Input, forwardRef, ElementRef, ViewChild, HostListener, AfterViewInit, ChangeDetectorRef, ViewChildren, QueryList, HostBinding, DestroyRef} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { filter, map, share, tap, fromEvent, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';

import { Animations } from 'src/app/animations/animations';
import { DropdownOption } from './mine-dropdown.interface';
import { DropdownType } from './dropdown-types.enum';
import { TippyModule } from '../tippy.module';

@Component({
	standalone: true,
	selector: 'mine-dropdown',
	templateUrl: './mine-dropdown.component.html',
	styleUrls: ['./mine-dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [Animations.fadeInOut],
	imports: [CommonModule, FormsModule, TippyModule],
	providers: [{       
		provide: NG_VALUE_ACCESSOR, 
		useExisting: forwardRef(() => MineDropdownComponent),
		multi: true     
	}]
})
export class MineDropdownComponent implements ControlValueAccessor, AfterViewInit {
	private readonly activeOptionClass = 'dropdown__option--active';
	private readonly alphanumericKeyLength = 1;
	private keydown$: Observable<KeyboardEvent>;
	searchTerm = '';

	@Input() id: string; //unique id is a must
	@Input() required: boolean;
	@Input() placeholder: string;
	@Input() options: DropdownOption[];
	@Input() error = '';
	@Input() height: string;
	@Input() selectedValuePrefix: string;
	@Input() hasCustom: boolean;
	
	@Input()
	set disable(value: boolean) {
		this.setDisabledState(value);
	}
	@Input() type: DropdownType = DropdownType.Form;	

	@ViewChild('dropdown') dropdown: ElementRef;
	@ViewChildren('option') optionsList: QueryList<ElementRef>;

	selectedValue: DropdownOption;
	markedOption: DropdownOption;
	selectedOptionElement: ElementRef;
	listOpen = false;
	disabled = false;

	constructor(
		private elementRef: ElementRef, 
		private cdr: ChangeDetectorRef,
		private destroyRef: DestroyRef,
	) {}

	ngAfterViewInit(): void {
		if (this.dropdown) {
			this.dropdown.nativeElement.style.height = this.height;
		}

		this.keydown$ = fromEvent<KeyboardEvent>(document, 'keyup').pipe(
			filter(() => this.listOpen), // apply only when panel is open
			share(),
			takeUntilDestroyed(this.destroyRef)
		);

		this.registerOnKeyboardSearch();
		this.registerOnPressEnter();
		this.indent();
	}

	@HostBinding('class') get hostClass() {
		return this.type;
	}

  	@HostListener('document:click', ['$event'])
	onClick(event: Event) {
		this.searchTerm = '';
		const clickedInside = this.elementRef.nativeElement.contains(event?.target);
		if (!clickedInside) {
			this.closeOptionsPanel();
		}
	}

	onOptionChanged(selectedValue: DropdownOption): void {
		this.value = selectedValue;
		this.closeOptionsPanel();
	}

  	closeOptionsPanel() {
		this.listOpen = false;
		this.searchTerm = '';
		if (this.selectedOptionElement?.nativeElement) {
			const el = this.selectedOptionElement.nativeElement;
			el.classList.remove(this.activeOptionClass);
		}

		this.cdr.detectChanges();
	}

	//implementation of reactive form
	set value(val: DropdownOption) {
		this.selectedValue = val;
		this.onChange(val);
	}

	onChange = (val: DropdownOption) => {};
	onTouch = (val: string) => {};

	writeValue(value: DropdownOption): void {
		this.value = value;
		this.cdr.detectChanges();
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}

	trackByFn(index: number, option: DropdownOption): string {
		return option.id;
	}

	private registerOnKeyboardSearch(): void {
		this.keydown$
			.pipe(
				tap((event) => this.handleDelete(event.key)),
				filter((event) => event.key.length === this.alphanumericKeyLength), // ignore non-alphanumeric keys
				tap((event) => (this.searchTerm += event.key)),
				tap(() => this.applySearchTerm())
			)
			.subscribe();
	}

	private handleDelete(key: string): void {
		if(key === "Backspace" || key === "Delete") {
			this.searchTerm = this.searchTerm.slice(0,-1);
			this.cdr.detectChanges();
		}
	}

	private registerOnPressEnter(): void {
		this.keydown$
			.pipe(
				filter((event) => event.key === 'Enter'),
				map(() => this.onOptionChanged(this.markedOption ?? this.selectedValue)),
				tap(() => this.elementRef.nativeElement.click()),
				tap(() => this.cdr.detectChanges())
			)
			.subscribe();
	}

	private applySearchTerm(): void {
		this.markedOption = this.options.find(
			(o) => o.value?.toLowerCase().startsWith(this.searchTerm.toLowerCase())
		);

		if (!!this.markedOption) {
			this.selectedOptionElement?.nativeElement.classList.remove(this.activeOptionClass);
			this.selectedOptionElement = this.optionsList
				.toArray()
				.find((option) => option.nativeElement.innerHTML?.trim() === this.markedOption.value);
			
			if (this.selectedOptionElement?.nativeElement) {
				const el = this.selectedOptionElement?.nativeElement;
				el.classList.add(this.activeOptionClass);
				el.scrollIntoView(true);
			}
		}
		this.cdr.detectChanges();
	}

	private indent(): void {
		for (let i = 1; i <= 3; i++) {
			const items = this.elementRef.nativeElement.querySelectorAll(`.dropdown__option--indent[data-indent='${i}']`);
			if (items?.length) {
				items[0].style.marginTop = '5px';
				items[items.length-1].style.marginBottom = '5px';
			}
			else {
				break;
			}
		}
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.cdr.detectChanges();
	}
}