
export enum EditResult {
    Success = "Success",
    RopaConflict = "RopaConflict",
}

export enum ReviewStatus {
    Draft = "Draft",
    Pending = "Pending",
    Approved = "Approved"
}

export enum DataTypeSource {
    PIIDIscovered = 'PIIDIscovered',
    Manual = 'Manual',
    Custom = 'Custom',
}

export enum SuggestedStateEnum {
    Accepted = 'Accepted',
    Suggested = 'Suggested',
    Rejected = 'Rejected',
}

export enum SystemState {
    Inventory = 'Inventory',
    Trash = 'Trash',
    Radar = 'Radar',
    Unknown = 'Unknown'
}

export enum DataSourceOriginEnum {
    Unknown = "Unknown",
    Manual = "Manual",
    Sso = "Sso",
    GoogleSso = "GoogleSso",
    Email = "Email",
    Website = "Website",
    Automatic = "Automatic", //this is in case it was by discovery but don’t know which
    Cloud = "Cloud",
    AwsCloud = "AwsCloud",
    OktaSso = "OktaSso",
    GcpCloud = "GcpCloud",
 }

 export enum EmployeeDataPointType {
    Text = "Text",
    Date = "Date",
    Number = "Number",
 }
 
export enum AssetTypeEnum {
    Vendor = "Vendor",
    DeveloperTool = "DeveloperTool",
    Employee = "Employee"
}

export enum AssetStateEnum {
    InList = "InList",
    Removed = "Removed",
    Skipped = "Skipped"
}