import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LoggerService } from '../logger/logger.service';
import { ContentPipe } from './content/content.pipe';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
    private readonly loggerName = 'FileUploadService';
    
    constructor(
        private loggerService: LoggerService,
        private contentPipe: ContentPipe
    ) { }
    
    upload(file: File, url: string): Observable<unknown> {
        const formData: FormData = new FormData();
        formData.append('file', file, file.name);

        return new Observable(observer => {
            fetch(url, {
                    method: 'POST',
                    body: formData
                })
                .then(data => {
                    observer.next(data);
                    observer.complete();
                })
                .catch(err => {
                    this.loggerService.error(this.loggerName, `Failed to upload file: ${err.message}`);
                    observer.error(err)
                });
        });
    }

    validateFile(file: File): string | null {
        // Validate file type
        const allowedMimeTypes = ['image/png', 'image/jpeg'];
        if (!allowedMimeTypes.includes(file.type)) {
            return this.contentPipe.transform('common.invalidFileTypeError');
        }

        // Validate file size (10MB maximum)
        const maxSizeInMB = 10;
        const maxSizeInBytes = maxSizeInMB * 1024 * 1024;
        if (file.size > maxSizeInBytes) {
            return this.contentPipe.transform('common.invalidFileSizeError');
        }
        
        return null;
    }
}