import { MineNoPermissionsComponent } from './shared/mine-no-permissions/mine-no-permissions.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// API //
import { RoutesManager } from './shared/models/routes.interfaces';

// SERVICES //
import { CustomPreloadingStrategy } from './services/custom-preloading.strategy';

// GUARDS //
import { HomeGuard } from './home/guard/home.guard';
import { UserRoleGuard } from './auth/user-role.guard';
import { AuthGuard, CompanyVerificationGuard } from './auth/auth.guard';
import { DashboardRoleGuard } from './dashboard/dashboard-user-role.guard';

// SHARED COMPONENTS //
import { ErrorPageComponent } from './shared/error-page/error-page.component';
import { FullPathMatch, PrefixPathMatch } from './shared/models/routes.interfaces';
import { PortalNoPermissionsComponent } from './shared/portal-no-permissions/portal-no-permissions.component';

// COMPONENTS //
import { HomeComponent } from './home/home.component';
import { DpoAdvisorComponent } from './dpo-advisor/dpo-advisor.component';
import { PostCheckoutComponent } from './core/post-checkout/post-checkout.component';
import { ConnectionChannelsComponent } from './channels/connection-channels/connection-channels.component';
import { IntegrationOauthCallbackComponent } from './systems/system-page/system-request-handling/integration-oauth/integration-oauth-callback/integration-oauth-callback.component';
import { FeatureFlagGuard } from './feature-flag/feature-flag.guard';
import { FeatureFlags } from './api/models/profile/profile-feature-flags.enum';

const routes: Routes = [
	{
		path: RoutesManager.pageNotAllowed,
		canMatch: [ AuthGuard, CompanyVerificationGuard ],
		component: MineNoPermissionsComponent
	},
	{
		path: RoutesManager.pageNotFound,
		data: { code: 404 },
		component: ErrorPageComponent
	},
	{
		path: RoutesManager.portalNoPermissions,
		data: { showHeader: true },
		component: PortalNoPermissionsComponent,
	},
	{
		path: `${RoutesManager.data_sources}/${RoutesManager.oauth_callback}`,
		data: { showSidenav: false },
        component: IntegrationOauthCallbackComponent
    },
	{
		path: RoutesManager.postCheckout,
		component: PostCheckoutComponent
	},
	{
		path: RoutesManager.integrations,
		redirectTo: RoutesManager.data_sources,
		// We use prefix match to support redirect after integration's OAuth flow
		pathMatch: PrefixPathMatch
	},
	{
		path: RoutesManager.ai_assets,
		canMatch: [ AuthGuard, CompanyVerificationGuard ],
		canActivate: [ FeatureFlagGuard ],
		data: { featureFlagName: FeatureFlags.DevAIDiscoveryPhase1 },
		loadChildren: async () => (await import('./ai-assets/ai-assets.module')).AiAssetsModule,
	},
	{
		path: RoutesManager.ai_assessments,
		canMatch: [ AuthGuard, CompanyVerificationGuard ],
		canActivate: [ FeatureFlagGuard ],
		data: { featureFlagName: FeatureFlags.DevAIAssessments },
		loadChildren: async () => (await import('./ai-assessments/ai-assessments.module')).AiAssessmentsModule,
	},
	{
		path: RoutesManager.systems,
		data: { preload: true },
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async() => (await import('./systems/systems.module')).SystemsModule,
	},
	{
		path: RoutesManager.requests,
		data: { preload: true },
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async () => (await import('./requests/requests.module')).RequestsModule,
	},
	{
		path: RoutesManager.automations,
		data: { preload: true },
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async () => (await import('./automations/automations.module')).AutomationsModule,
	},
	{
		path: RoutesManager.tasks,
		canMatch: [ AuthGuard, CompanyVerificationGuard ],
		loadChildren: async () => (await import('./tasks/tasks.module')).TasksModule,
	},
	{
		path: RoutesManager.dashboard,
		canMatch: [ AuthGuard, CompanyVerificationGuard, DashboardRoleGuard ],
		loadChildren: async () => (await import('./dashboard/dashboard.module')).DashboardModule,
	},
	{
		path: RoutesManager.profileHome,
		canMatch: [ AuthGuard, CompanyVerificationGuard ],
		loadChildren: async () => (await import('./profile/profile.module')).ProfileModule,
	},
	{
		path: RoutesManager.developers,
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async () => (await import('./developers/developers.module')).DevelopersModule,
	},
	{
		path: RoutesManager.plans,
		canMatch: [ AuthGuard, CompanyVerificationGuard ],
		loadChildren: async () => (await import('./plans/plans.module')).PlansModule,
	},
	{
		path: RoutesManager.privacyCenter,
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		data: { preload: true },
		loadChildren: async () => (await import('./privacy-center/privacy-center.module')).PrivacyCenterModule,
	},
	{
		path: RoutesManager.consentManagement,
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async () => (await import('./consent-management/consent-management.module')).ConsentManagementModule,
	},
	{
		path: RoutesManager.emailForwarding,
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async () => (await import('./import-ticket/import-ticket.module')).ImportTicketModule,
	},
	{
		path: RoutesManager.onboarding,
		data: { hideTrialBanner: true, changeBackground: true, disablePadding: true, showSidenav: false },
		loadChildren: async () => (await import('./onboarding/onboarding.module')).OnboardingModule,
	},
	{
		path: RoutesManager.homePage,
		component: HomeComponent,
		canMatch: [ AuthGuard, CompanyVerificationGuard, HomeGuard ],
		loadChildren: async () => (await import('./home/home.module')).HomeModule,
	},
	{
		path: RoutesManager.employees,
		data: { preload: true }, // remove when employees scale will be open
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async() => (await import('./employees/employees.module')).EmployeesModule,
	},
	{
		path: RoutesManager.processingActivities,
		data: { preload: true },
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async() => (await import('./processing-activities/processing-activity.module')).ProcessingActivityModule,
	},
	{
		path: RoutesManager.ropa,
		redirectTo: RoutesManager.processingActivities,
		pathMatch: FullPathMatch
	},
	{
		path: RoutesManager.dataTypes,
		redirectTo: RoutesManager.dataClassifier,
		pathMatch: FullPathMatch
	},
	{
		path: RoutesManager.dataMappingLogin,
		data: { hideTrialBanner: true, disablePadding: true, showSidenav: false },
		loadChildren: async() => (await import('./data-mapping-login/data-mapping-login.module')).DataMappingLoginModule,
	},
	{
		path: RoutesManager.channels,
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		component: ConnectionChannelsComponent,
		loadChildren: async() => (await import('./channels/channels.module')).ChannelsModule,
	},
	{
		path: RoutesManager.companySettings,
		data: { preload: true },
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async() => (await import('./company-settings/company-settings.module')).CompanySettingsModule,
	},
	{
		path: RoutesManager.userSearch,
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async() => (await import('./user-search/user-search.module')).UserSearchModule,
	},
	{
		path: RoutesManager.vendorManagement,
		data: { preload: true },
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async() => (await import('./vendor-management/vendor-management.module')).VendorManagementModule,
	},
	{
		path: RoutesManager.policies,
		data: { preload: true },
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async() => (await import('./policies/policies.module')).PoliciesModule,
	},
	{
		path: RoutesManager.dpoAdvisor,
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		data: { fullWidthMainContent: true },
		component: DpoAdvisorComponent,
		loadChildren: async() => (await import('./dpo-advisor/dpo-advisor.module')).DpoAdvisorModule,
	},
	{
		path: RoutesManager.impactAssessment,
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async() => (await import('./impact-assessment/impact-assessment.module')).ImpactAssessmentModule,
	},
	{
		path: RoutesManager.discovery,
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async() => (await import('./discovery-tools/discovery-tools.module')).DiscoveryToolsModule,
	},
	{
		path: RoutesManager.radar,
		data: { preload: true },
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async() => (await import('./radar/radar.module')).RadarModule,
	},
	{
		path: RoutesManager.unusedAssets,
		canMatch: [ AuthGuard, CompanyVerificationGuard, UserRoleGuard ],
		loadChildren: async() => (await import('./unused-assets/unused-assets.module')).UnusedAssetsModule,
	},
	{
		path: RoutesManager.dataClassifier,
		canMatch: [ AuthGuard, CompanyVerificationGuard ],
		loadChildren: async() => (await import('./pii-classifier/pii-classifier.module')).PiiClassifierModule,
	},
	{
		path: RoutesManager.dsr_setup,
		canMatch: [ AuthGuard, CompanyVerificationGuard ],
		canActivate: [ FeatureFlagGuard ],
		data: { featureFlagName: FeatureFlags.DevPrivacyRights, preload: true },
		loadChildren: async() => (await import('./dsr/dsr-setup/dsr-setup.module')).DsrSetupModule,
	},
	{
		path: RoutesManager.datamapping,
		redirectTo: RoutesManager.dataMapping,
		pathMatch: FullPathMatch
	},
	{
		path: RoutesManager.piiClassifier,
		redirectTo: RoutesManager.dataClassifier,
	},
	{
		path: '',
		redirectTo: RoutesManager.homePage,
		pathMatch: FullPathMatch
	},
	{
		path: '**',
		redirectTo: RoutesManager.pageNotFound,
		pathMatch: FullPathMatch
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: CustomPreloadingStrategy,
			scrollPositionRestoration: 'top',
			anchorScrolling: 'enabled'
		}),
	],
	exports: [
		RouterModule
	],
	providers: [
		CustomPreloadingStrategy
	]
})
export class AppRoutingModule {}