import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError, merge, of, tap, catchError, map, filter, share, switchMap, take, EMPTY } from 'rxjs';

import { environment } from 'src/environments/environment';
import { ApiClientAuthService } from '../api/api-client-auth.service';
import { ApiClientSessionsService } from '../api/api-client-sessions.service';
import { FeatureFlagService } from '../feature-flag/state/feature-flag.service';
import { FeatureFlagQuery } from '../feature-flag/state/feature-flag.query';
import { ProfileService } from '../profile/state/profile.service';
import { PusherService } from '../mineos-chatbot/pusher.service';
import { LoggerService } from '../logger/logger.service';

import { OAuthAuthorizationResponse } from '../api/models/auth/o-auth-authorization-response';
import { AuthProviders } from '../api/models/auth/auth-providers';
import { LoginCallbackResponse } from './login-callback-response';
import { ProfileQuery } from '../profile/state/profile.query';
import { FeatureFlags } from '../api/models/profile/profile-feature-flags.enum';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
	private readonly loggerName: string = 'AuthService';
	
	private isLoggedIn = new BehaviorSubject<boolean>(false);
	isLoggedIn$ = this.isLoggedIn.asObservable();

	private loggedInUserId = new BehaviorSubject<string>('');
	loggedInUserId$ = this.loggedInUserId.asObservable();

	constructor(
		private pusherService: PusherService,
		private apiClientAuth : ApiClientAuthService,
		private apiClientSessions: ApiClientSessionsService,
		private featureFlagService: FeatureFlagService,
		private featureFlagQuery: FeatureFlagQuery,
		private profileService: ProfileService,
		private profileQuery: ProfileQuery,
		private logger: LoggerService,
	) {	}

	initialize(): Observable<void> {
		this.logger.debug(this.loggerName, 'initialize()');

		return this.featureFlagService.getPublicFeatureFlagsFromServer().pipe(
			switchMap(() => this.initializeWithCookie()),
			take(1)
		);
	}

	private initializeWithCookie(): Observable<void> {
			return this.apiClientSessions.isSessionActive().pipe(
				tap(res => this.loggedInUserId.next(res.userId)),
				tap(() => this.isLoggedIn.next(true)),
				switchMap(() => this.featureFlagService.getFeatureFlagsFromServer()),
				catchError(error => {
					this.logger.error(this.loggerName, error.message);
					return EMPTY;
				})
			);
	}

	loginCallback(authCallbackParams: OAuthAuthorizationResponse, provider: AuthProviders): Observable<Partial<LoginCallbackResponse>> {
		this.logger.debug(this.loggerName, 'loginCallback()');
		const callbackReponse: Partial<LoginCallbackResponse> = {};
		
		const login$ = this.apiClientAuth.getJwt(authCallbackParams, provider).pipe(
				tap(response => callbackReponse.authenticationSuccess = response.authenticationSuccess),
				catchError(error => {
					return throwError(error.error);
				}),
				share()
			);

		const success$ = login$.pipe(
			filter(response => response.authenticationSuccess),
			tap(response => callbackReponse.newUser = response.isNewUser),
			switchMap(() => this.apiClientSessions.isSessionActive()),
			tap(res => this.loggedInUserId.next(res?.userId ?? '')),
			tap(() => this.isLoggedIn.next(true)),
			switchMap(() => this.featureFlagService.getFeatureFlagsFromServer()),
			map(() => callbackReponse)
		);

		const failure$ = login$.pipe(
			filter(response => !response.authenticationSuccess),
			map(response => {
				callbackReponse.restartWithConsent = response.restartWithConsent;
				callbackReponse.unauthorizedResponseReason = response.unauthorizedResponseReason;
				callbackReponse.identityProvider = response.identityProvider;
				return callbackReponse;
			})
		)

		return merge(success$, failure$);
	}

	logout(): Observable<void> {
		if (this.featureFlagQuery.getFlag(FeatureFlags.DevMineOSChatbot)) {
			// remove copilot last ready message
			sessionStorage.removeItem(`fe_ready-${this.profileQuery.getCompanyId()}`);
			// disconnect from pusher
			this.pusherService.disconnect();
		}
		
		// logout from auth0 account 
		let provider = this.profileQuery.getValue().identityProvider?.toLowerCase();
		if (provider === AuthProviders.auth0) {
			window.location.href = `${environment.api.accessPointUrl}auth0/logout?redirect=${environment.routing.permissionsDeclinedUrl}`;
			return of(null);
		}
		else if (provider === AuthProviders.sso) {
			window.location.href = `${environment.api.accessPointUrl}ssoAuthPortal/logout?redirect=${environment.routing.permissionsDeclinedUrl}`;
			return of(null);
		}

		this.isLoggedIn.next(false);
		this.loggedInUserId.next('');
		
		return this.featureFlagService.logout().pipe(
			tap(() => this.profileService.logout()),
		);
	}

	userLogoutSession(): Observable<void> { //use this for sign out buttons
		return this.apiClientSessions.logout().pipe(
			switchMap(() => this.logout())
		);
	}

	// query param &signup=true is for auth0 only - redirecting to signup / login page in auth0
	getLoginUrl(provider: string, consent: boolean = false, isSignupFlow = false): string {
		const redirectUrl = environment.authentication.redirectUrl[provider];
		const signupParam = (provider === AuthProviders.auth0 || provider === AuthProviders.sso) && isSignupFlow;
		return environment.api.accessPointUrl + `${provider}${provider === AuthProviders.auth0 ? '' : 'authportal'}/login?rwc=${consent}${signupParam ? '&signup=true' : ''}&redirect=${redirectUrl}`;
	}

	isAuthenticated(): boolean {
		return this.isLoggedIn.getValue();
	}

	getLoggedInUserId(): string {
		return this.loggedInUserId.getValue();
	}
}
