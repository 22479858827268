import { Injectable } from '@angular/core';
import { merge, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs';
import { ApiClientPrivacyCenterService } from 'src/app/api/api-client-privacy-center.service';
import { PrivacyCenterRequestForm } from 'src/app/api/models/privacy-center/privacy-center.interface';
import { LoggerService } from 'src/app/logger/logger.service';
import { PrivacyCenterQuery } from './privacy-center.query';
import { PrivacyCenterStore } from './privacy-center.store';
import { ApiClientDsrRightsService } from 'src/app/api/dsr/api-client-dsr-rights.service';

@Injectable({ 
	providedIn: 'root' 
})
export class PrivacyCenterService {
	private readonly loggerName: string = 'PrivacyCenterService';
	private privacyCenterBaseUrl: string = '';

	constructor(private privacyCenterQuery: PrivacyCenterQuery,
				private privacyCenterStore: PrivacyCenterStore,
				private apiClientPrivacyCenter: ApiClientPrivacyCenterService,
				private logger: LoggerService,
				private apiClientDsrRightsService: ApiClientDsrRightsService,
			) { 
	}

	getLoadedPrivacyCenter(): Observable<PrivacyCenterRequestForm> {
		return this.privacyCenterQuery.selectLoading().pipe(filter(loading => !loading), switchMap(() => this.privacyCenterQuery.select()));
	}

	getPrivacyCenterBaseUrl(): string {
		return this.privacyCenterBaseUrl;
	}

	getPrivacyCenterConfig(): Observable<PrivacyCenterRequestForm> {

		const loading$ = this.privacyCenterQuery.selectLoading();

		const dataFromServer$ = loading$.pipe(
				filter(response => response),
				switchMap(() => this.setPrivacyCenterConfig())
			);

		const dataFromStore$ = loading$.pipe(
				filter(response => !response),
				switchMap(() => this.privacyCenterQuery.select())
			);

		return merge(dataFromStore$, dataFromServer$);
	}

	private setPrivacyCenterConfig(): Observable<PrivacyCenterRequestForm> {
		this.logger.debug(this.loggerName, 'setPrivacyCenterConfig()');
		return this.apiClientPrivacyCenter.getConfiguration().pipe(
			tap((res)=> this.privacyCenterBaseUrl = res.baseUrl),
			map(response => response.requestForm),
			tap((config: PrivacyCenterRequestForm) => this.privacyCenterStore.update(config)),
			tap(() => this.privacyCenterStore.setLoading(false)),
			tap(() => this.logger.info(this.loggerName, "Got privacy center form data")),
			catchError(error => {
				this.logger.error(this.loggerName, `closeRequest() Error: ${error.name} ,${error.message}`);
				throwError(error.error);
				return this.privacyCenterQuery.select();
			}),
		)
	}

	updatePrivacyCenterConfig(config: PrivacyCenterRequestForm): Observable<any> {
		return this.apiClientPrivacyCenter.setConfiguration(config as PrivacyCenterRequestForm).pipe(
			tap(() => this.privacyCenterStore.update(config)),
			tap(() => this.logger.info(this.loggerName, 'privacy center request form configurations saved')),
		);
	}

	// disable states checkbox if there is at list one privacy right with eligible state
	hasRightsWithStates(): Observable<boolean> {
        return this.apiClientDsrRightsService.getPrivacyRightsExtended().pipe(
            map(res => res.privacyRights),
            map(privacyRights => privacyRights.filter(privacyRight => privacyRight.eligibleRegions.filter(e => e.state && e.state !== '').length > 0).length > 0)
        );
    }
}
