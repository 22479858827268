import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

import { HtmlDecodePipe } from 'src/app/shared/pipes/html-decode.pipe';
import { LanguageForm, LocalizationConfig, PrivacyCenterRequestForm } from 'src/app/api/models/privacy-center/privacy-center.interface';

export function createInitialState(): PrivacyCenterRequestForm {
	return {
	    enabled: undefined,
		lastModified: undefined,
		customPath: undefined,
		ticketsCreated: undefined,
		lastUsed: undefined,
		state: undefined,
		mobileNumber: undefined,		
		address: undefined,
		file: undefined
	}
};

@Injectable({ 
  	providedIn: 'root' 
})
@StoreConfig({ 
	name: 'privacyCenter',
})
export class PrivacyCenterStore extends Store<PrivacyCenterRequestForm> {
	
	constructor(
		private htmlDecodePipe: HtmlDecodePipe
	) {
		super(createInitialState());
		this.setLoading(true);
	}

	akitaPreUpdate(prevState: PrivacyCenterRequestForm, nextState: PrivacyCenterRequestForm): PrivacyCenterRequestForm {
		return this.getDecodedEntity(nextState);
	}

	private getDecodedEntity(configuration: PrivacyCenterRequestForm): PrivacyCenterRequestForm {
		return {
			...configuration,
			localization: this.getDecodedLocalization(configuration.localization)
		};
	}
	
	private getDecodedLocalization(localization?: LocalizationConfig): LocalizationConfig {
		if (!localization) {
			return { 
				forms: [] 
			} as LocalizationConfig;
		}
		
		const languageForms: LanguageForm[] = [];
		localization.forms?.forEach((locale: LanguageForm) => {
			languageForms.push({
				...locale,
				description: this.htmlDecodePipe.transform(locale.description),
				fileCustomInfo: this.htmlDecodePipe.transform(locale.fileCustomInfo),
				formCustomEntries: this.htmlDecodePipe.transform(locale.formCustomEntries),
				title: this.htmlDecodePipe.transform(locale.title),
			} as LanguageForm);
		});
	
		return { 
			forms: languageForms 
		} as LocalizationConfig;
	}
}
