export enum FieldUsedInEnum {
    DataSource = 'DataSource',
    Activity = 'Activity',
    Dpia = 'Dpia',
}

export enum FieldInputType {
    Text = 'Text',
    Select = 'Select',
}
