import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

// COMPONENTS //
import { AppMenuComponent } from './app-menu/app-menu.component';
import { AppElasticSidenavComponent } from './app-elastic-sidenav/app-elastic-sidenav.component';
import { AppTemporaryMenuComponent } from './app-menu-temporary/app-menu-temporary.component';
import { AppHeaderWebsiteComponent } from './app-header-website/app-header-website.component';
import { TrialBannerComponent } from './trial-banner/trial-banner.component';
import { AppConfettiComponent } from './app-confetti/app-confetti.component';
import { PostCheckoutComponent } from './post-checkout/post-checkout.component';
import { ActivatePortalDialogComponent } from './activate-portal-dialog/activate-portal-dialog.component';
import { OnboardingProgressBarComponent } from './app-elastic-sidenav/onboarding-progress-bar/onboarding-progress-bar.component';
import { MineExpansionPanelComponent } from '../shared/mine-expansion-panel/mine-expansion-panel.component';
import { MineButtonPrimaryComponent } from '../shared/mine-button-primary/mine-button-primary.component';
import { MineButtonSecondaryComponent } from '../shared/mine-button-secondary/mine-button-secondary.component';
import { MineButtonTertiaryComponent } from '../shared/mine-button-tertiary/mine-button-tertiary.component';
import { MineServiceIconComponent } from '../shared/mine-service-icon/mine-service-icon.component';
import { MineDropdownComponent } from '../shared/mine-dropdown/mine-dropdown.component';
import { MineXButtonComponent } from '../shared/mine-x-button/mine-x-button.component';
import { MineSpinnerComponent } from '../shared/mine-spinner/mine-spinner.component';
import { IsDateInPastPipe } from "../shared/pipes/is-date-in-past.pipe";
import { SafePipe } from '../shared/pipes/safe.pipe';
import { TippyModule } from '../shared/tippy.module';
import { ContentPipeModule } from '../services/content/content-pipe.module';
import { AppSidenavContent } from './app-sidenav-content/app-sidenav-content.component';
import { MineCheckMarkComponent } from '../shared/mine-check-mark/mine-check-mark.component';
import { MinePermissionsDirective } from '../shared/directives/mine-permissions.directive';

export const DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY',
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
	declarations: [
		AppHeaderWebsiteComponent,
		AppElasticSidenavComponent,
		AppTemporaryMenuComponent,
		AppMenuComponent,
		AppConfettiComponent,
		PostCheckoutComponent,
		ActivatePortalDialogComponent,
  		TrialBannerComponent,
		OnboardingProgressBarComponent,
  		AppSidenavContent,
	],
	imports: [
		CommonModule,
		RouterModule,
		FormsModule,
		TippyModule,
		ReactiveFormsModule,
		MatDatepickerModule, 
		MatNativeDateModule,
		BrowserModule,
		BrowserAnimationsModule,
		ContentPipeModule,
		MineServiceIconComponent,
		MineButtonPrimaryComponent,
		MineButtonSecondaryComponent,
		MineButtonTertiaryComponent,
		MineExpansionPanelComponent,
		MineDropdownComponent,
		MineXButtonComponent,
		MineSpinnerComponent,
		MineCheckMarkComponent,
		MinePermissionsDirective,
		NgOptimizedImage,
    	IsDateInPastPipe,
		SafePipe,
	],
	exports: [
		BrowserModule,
		BrowserAnimationsModule,
		AppHeaderWebsiteComponent,
		AppElasticSidenavComponent,
		AppConfettiComponent,
		PostCheckoutComponent,
		TrialBannerComponent
	],
	providers: [
		IsDateInPastPipe,
		{ provide: MAT_DATE_LOCALE, useValue: 'en-US' },
    	{ provide: MAT_DATE_FORMATS, useValue: DATEPICKER_FORMATS },
	]
})
export class CoreModule { }
