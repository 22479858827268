export enum EmployeesCountFilterOptions {
  Few = 'few',
  Some = 'some',
  Several = 'several',
  Many = 'many',
  Zero = 'zero',
  Unknown = 'unknown',
}

export enum GeneralFilterOptions {
  All = 'all',
  Feedback = 'feedback',
  Recommended = 'recommended',
}

export enum OriginFilterOptions {
  Email = 'Email',
  Sso = 'Sso',
  Cloud = 'Cloud',
  Website = 'Website',
}

export enum CategoriesFilterOptions {
  Analytics = 'Analytics',
  CollaborationProductivity = 'CollaborationProductivity',
  Communications = 'Communications',
  CustomerService = 'CustomerService',
  DataInfrastructure = 'DataInfrastructure',
  Development = 'Development',
  Ecommerce = 'Ecommerce',
  Finance = 'Finance',
  GovernanceLegal = 'GovernanceLegal',
  HealthWellnessFitness = 'HealthWellnessFitness',
  HR = 'HR',
  IT = 'IT',
  Marketing = 'Marketing',
  Operations = 'Operations',
  Sales = 'Sales',
  Security = 'Security',
  SocialMedia = 'SocialMedia'
}

export enum RadarFilterGroups {
  Usage = 'usage',
  EmployeesCount = 'employeesCount',
  Category = 'Category',
  General = 'general',
  Origin = 'origin'
}