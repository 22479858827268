import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { CustomViewsState, CustomViewsStore } from './custom-views.store';
import { TableCustomView } from 'src/app/api/models/custom-views/custom-views.interface';
import { TablesEnum } from 'src/app/shared/table-state-service/table-state.enum';

@Injectable({ 
	providedIn: 'root' 
})
export class CustomViewsQuery extends QueryEntity<CustomViewsState> {

	constructor(protected store: CustomViewsStore) {
		super(store);
	}

	selectViews(viewType: TablesEnum): Observable<TableCustomView[]> {
		return this.selectAll({
			filterBy: ({ type }) => type === viewType
		});
	}

	selectViewCount(viewType: TablesEnum): Observable<number> {
		return this.selectCount(({ type }) => type === viewType);
	}

}
