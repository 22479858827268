import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { CustomFieldsState, CustomFieldsStore } from './custom-fields.store';
import { MineSort } from 'src/app/shared/mine-sort/mine-sort.interface';
import { CustomFieldV2 } from 'src/app/api/models/company-settings/custom-fields.interface';
import { CustomValuesTableColumns } from '../../customization/custom-values/custom-values.enum';
import { SortDirectionEnum } from 'src/app/shared/sort-direction.enum';
import { FieldUsedInEnum } from 'src/app/api/models/company-settings/custom-fields.enum';

@Injectable({ 
	providedIn: 'root' 
})
export class CustomFieldsQuery extends QueryEntity<CustomFieldsState> {

	constructor(protected store: CustomFieldsStore) {
		super(store);
	}

	selectAllAndSort(sort: MineSort): Observable<CustomFieldV2[]> {
		return this.selectAll({
			sortBy: (s1, s2) => sort?.active === CustomValuesTableColumns.Field ? this.sordByName(s1.name, s2.name, sort.direction) :
								sort?.active === CustomValuesTableColumns.UsedIn ? this.sordByUsedIn(s1.usedIn, s2.usedIn, sort.direction) : 1});
	}


	private sordByName(systemNameA: string, systemNameB: string, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
			systemNameA?.localeCompare(systemNameB) : systemNameB?.localeCompare(systemNameA);
	}

	private sordByUsedIn(systemA: FieldUsedInEnum, systemB: FieldUsedInEnum, direction: SortDirectionEnum): number {
		return direction === SortDirectionEnum.Asc ?
		systemA?.localeCompare(systemB) : systemB?.localeCompare(systemA);
	}

	selectCustomFieldsByType(type: FieldUsedInEnum): Observable<CustomFieldV2[]> {
		return this.selectAll({
			filterBy: ({ usedIn }) => usedIn === type,
		});
	}

	getCustomFieldsByType(type: FieldUsedInEnum): CustomFieldV2[] {
		return this.getAll({
			filterBy: ({ usedIn }) => usedIn === type,
		});
	}
}
