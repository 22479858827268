import { FeedbackStateEnum, FeedbackTypeEnum } from './../../api/models/feedback/feedback.enum';
import { Feedback, FeedbackRequest } from './../../api/models/feedback/feedback.interface';
import { ApiClientFeedbackService } from './../../api/api-client-feedback.service';
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of, tap, switchMap, first, map } from "rxjs";
import { ApiClientSystemsService } from "src/app/api/api-client-systems.service";
import { SystemsIconsResponse, UnverifiedSystem } from "src/app/api/models/systems/systems.interface";
import { LoggerService } from "src/app/logger/logger.service";
import { SystemsIconsService } from "src/app/systems/state/systems-icons.service";
import { UnverifiedSystemsStore } from "./unverified-systems.store";
import { MappingProgressResponse } from 'src/app/api/models/data-mapping/data-mapping.interface';
import { ApiClientDataMappingService } from 'src/app/api/api-client-data-mapping.service';
import { DataSourceOriginEnum } from 'src/app/api/models/systems/systems.enum';

@Injectable({
	providedIn: 'root',
})
export class UnverifiedSystemsService {
    private readonly loggerName: string = 'UnverifiedSystemsService';

    private readonly MineCustomSystemType = 'mine-custom';

    employeesScanNotCompleted = new BehaviorSubject<string[]>([]); // employees their scan didn't finished
    employeesScanNotCompleted$ = this.employeesScanNotCompleted.asObservable();

    constructor(
        private logger: LoggerService,
        private systemsIconsService: SystemsIconsService,
        private unverifiedSystemsStore: UnverifiedSystemsStore,
        private apiClientSystems: ApiClientSystemsService,
        private apiClientFeedback: ApiClientFeedbackService,
        private apiClientDataMappingService: ApiClientDataMappingService,
    ) { }

    init(): Observable<void> {
		this.logger.debug(this.loggerName, 'init()');
        return this.getUnverifiedSystemsFromServer();
	}

    getUnverifiedSystemsFromServer(): Observable<void> {
        return this.apiClientSystems.getUnverifiedSystems().pipe(
            first(),
            map(res => res?.result),
            switchMap(res => this.getSystemsWithIcons(res)),
            map(res => this.getUpdatedSystem(res)),
            map(systems => this.updateSystemsStore(systems)),            
        );
    }

    getUnverifiedSystem(systemId: string): Observable<UnverifiedSystem> {
        return this.apiClientSystems.getUnverifiedSystem(systemId).pipe(     
        );
    }

    //check - if not type email - change usage to null,
    //check - if not type email, sso, okta - change connected accounts to null
    private getUpdatedSystem(unverifiedSystems: UnverifiedSystem[]): UnverifiedSystem[] {
        const validOriginMethods = [DataSourceOriginEnum.Email, DataSourceOriginEnum.GoogleSso, DataSourceOriginEnum.OktaSso];

        return unverifiedSystems.map(system => {
            const updatedSystem = { ...system };
            if (!system.dataSourceOrigin.some(method => validOriginMethods.includes(method))) {
                updatedSystem.connectedAccounts = null;
                !!updatedSystem.systemUsage && (updatedSystem.systemUsage.discoveryScore = null);
              } else {
                if (!system.dataSourceOrigin.includes(DataSourceOriginEnum.Email)) {
                    !!updatedSystem.systemUsage && (updatedSystem.systemUsage.discoveryScore = null);
                }
                if (!system.dataSourceOrigin.some(method => validOriginMethods.includes(method))) {
                    updatedSystem.connectedAccounts = null;
                }
              }
              return updatedSystem;

          });
    }

    private updateSystemsStore(unverifiedSystems: UnverifiedSystem[] = []): void {
        this.unverifiedSystemsStore.set(unverifiedSystems);
    }
    
    private getSystemsWithIcons(unverifiedSystems: UnverifiedSystem[] = []): Observable<UnverifiedSystem[]> {
        const systemTypes = Array.from(new Set(unverifiedSystems.map(system => system.systemType))).filter(type => type !== this.MineCustomSystemType);
        if (systemTypes.length) {
            return this.systemsIconsService.getSystemsIcons(systemTypes).pipe(
                map(res => this.setIcons(unverifiedSystems, res)),
                tap(() => this.logger.info(this.loggerName, 'set icons of unverified systems')),
            );
        }

        return of([]);
    }

    private setIcons(unverifiedSystems: UnverifiedSystem[], res: SystemsIconsResponse): UnverifiedSystem[] {
        return unverifiedSystems.map(system => ({
                ...system,
                iconPath: res.icons[system.systemType]
            } as UnverifiedSystem));
    }

    removeUnverifiedSystemsFromStore(systems: string[]): void {
        this.unverifiedSystemsStore.remove(systems);
    }

    moveFromUnverifiedToTrash(systemIds: string[]): Observable<void> {
        return this.apiClientSystems.moveToTrash(systemIds).pipe(
            tap(() => this.removeUnverifiedSystemsFromStore(systemIds)),
        );
    }

    moveFromUnverifiedToInventory(systemIds: string[]): Observable<void> {
        return this.apiClientSystems.moveToInventory(systemIds).pipe(
            tap(() => this.removeUnverifiedSystemsFromStore(systemIds)),
        );
    }

    askFeedback(systemIds: string[], employeeId: string): Observable<void> {
        const body: FeedbackRequest = {
            feedbackTypes: [FeedbackTypeEnum.System],
            systemIds,
            assignedReviewerId: employeeId
        }

        return this.apiClientFeedback.requestFeedback(body).pipe(
            map(() => this.unverifiedSystemsStore.update(systemIds, {generalFeedbackState: FeedbackStateEnum.Pending}))
        );
    }

    getFeedbacks(systemId: string): Observable<Feedback[]> {
        return this.apiClientFeedback.getSystemFeedbacks(systemId).pipe(
            map(response => response.feedbackData.filter(feedback => feedback.feedbackState !== FeedbackStateEnum.Canceled)),
        );
    }

    updateFeedbackState(systemId: string, feedbackId: string, state: FeedbackStateEnum): Observable<void> {
        return this.apiClientFeedback.agentUpdateFeedbackState(feedbackId, state).pipe(
            tap(() => this.unverifiedSystemsStore.update(systemId, {generalFeedbackState: FeedbackStateEnum.None}))
        );
    }

    getRadarProgress(): Observable<MappingProgressResponse> {
		return this.apiClientDataMappingService.getDataMappingProgress();
	}
}
