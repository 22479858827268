import { TitleCasePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { produce } from 'immer';
import { UnverifiedSystem } from 'src/app/api/models/systems/systems.interface';

export interface UnverifiedSystemsState extends EntityState<UnverifiedSystem> { }

@Injectable({ 
	providedIn: 'root' 
})
@StoreConfig({ 
	name: 'unverifiedSystems',
	idKey: 'systemId',
	producerFn: produce
})
export class UnverifiedSystemsStore extends EntityStore<UnverifiedSystemsState> {
	
	constructor(
		private titleCasePipe: TitleCasePipe,
	) {
		super();
		this.akitaPreAddEntity = this.akitaPreAddEntity.bind(this);
	}

	akitaPreAddEntity(system: UnverifiedSystem) {
		return {
			...system,
			name: this.titleCasePipe.transform(system.name),
		};
	}
}