import { Params } from "@angular/router";
import { AuthProviders } from "../api/models/auth/auth-providers";
import { EmployeesAsyncActions } from "../heavy-action-dialog/heavy-action-dialog.enum";

export interface RedirectUrl {
   url: string[];
   queryParams: Params;
}

export class LocalStorageHelper {
   private static redirectUrl: string = 'redirectUrl';
   private static onboardingStarted: string = 'onboardingStarted';
   private static onboardingRedirectUrl: string = 'onboardingRedirectUrl';
   private static contentLanguage: string = 'contentLanguage';
   private static multipleWorkspaces: string = 'multipleWorkspaces';
   private static connectingToMine: string = 'connectingToMine'; // save to local storage when user starts import
   private static privacyCenterChangesAlert = 'privacyCenterChangesAlert';
   private static heavyActionDialog = 'heavyActionDialog';

   static setRedirect(url: RedirectUrl): void {
      localStorage.setItem(this.redirectUrl, JSON.stringify(url));
   }

   static getRedirect(): RedirectUrl {
      return JSON.parse(localStorage.getItem(this.redirectUrl));
   }

   static removeRedirect(): void {
      localStorage.removeItem(this.redirectUrl);
   }

   static setOnboardingRedirect(url: RedirectUrl): void {
      localStorage.setItem(this.onboardingRedirectUrl, JSON.stringify(url));
   }

   static getOnboardingRedirect(): RedirectUrl {
      return JSON.parse(localStorage.getItem(this.onboardingRedirectUrl));
   }

   static removeOnboardingRedirect(): void {
      localStorage.removeItem(this.onboardingRedirectUrl);
   }

   // need to know what type of onboarding started for some guards later
   static setOnboardingStarted(provider: AuthProviders): void {
      localStorage.setItem(this.onboardingStarted, provider);
   }

   static getOnboardingStarted(): string {
      return localStorage.getItem(this.onboardingStarted);
   }

   static removeOnboardingStarted(): void {
      localStorage.removeItem(this.onboardingStarted);
   }

   static getContentLanguage(): string {
      const language = localStorage.getItem(this.contentLanguage);
      if(language === '' || language === undefined || language === null) {
         return 'en';
      }

      return language;
   }

   // save map with userId + last connected companyId
   static setUserIdAndConnectedCompany(userId: string, companyId: string): void {
      const multipleWorkspacesMap = new Map(JSON.parse(localStorage.getItem(this.multipleWorkspaces))) as Map<string, string>;
      multipleWorkspacesMap.set(userId, companyId);
      localStorage.setItem(this.multipleWorkspaces, JSON.stringify(Array.from(multipleWorkspacesMap.entries())));
   }

   static removeUserIdAndConnectedCompany(userId: string): void {
      const multipleWorkspacesMap = new Map(JSON.parse(localStorage.getItem(this.multipleWorkspaces))) as Map<string, string>;
      multipleWorkspacesMap.delete(userId);
      localStorage.setItem(this.multipleWorkspaces, JSON.stringify(Array.from(multipleWorkspacesMap.entries())));
   }

   static getLastConnectedCompanyId(userId: string): string {
      const multipleWorkspacesMap = new Map(JSON.parse(localStorage.getItem(this.multipleWorkspaces))) as Map<string, string>;
      if (userId && multipleWorkspacesMap.has(userId)) {
         return multipleWorkspacesMap.get(userId); 
      }
      return undefined;
   }
   
   // insert to localstorage the companies that their status of import requests is "connecting to mine"
   static setConnectingToMine(companyId: string): void {
      localStorage.setItem(`${this.connectingToMine}-${companyId}`, 'true');
   }

   static hasConnectingToMine(companyId: string): boolean {
      return JSON.parse(localStorage.getItem(`${this.connectingToMine}-${companyId}`)) as boolean;
   }

   static removeConnectingToMine(companyId: string): void {
      localStorage.removeItem(`${this.connectingToMine}-${companyId}`);
   }

   static setPrivacyCenterChangesAlert(userId: string): void {
      localStorage.setItem(`${this.privacyCenterChangesAlert}-${userId}`, 'true');
   }

   static getPrivacyCenterChangesAlert(userId: string): boolean {
      return JSON.parse(localStorage.getItem(`${this.privacyCenterChangesAlert}-${userId}`)) as boolean;
   }

   static setHeavyActionDialog(action: EmployeesAsyncActions): void {
      localStorage.setItem(`${this.heavyActionDialog}-${action}`, 'true');
   }

   static hasHeavyActionDialogValue(action: EmployeesAsyncActions): boolean {
      return JSON.parse(localStorage.getItem(`${this.heavyActionDialog}-${action}`)) as boolean;
   }
}